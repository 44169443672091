import React, { useEffect, useState } from 'react'
import VimeoPlayer from '../vimeo-player'
import * as util from '../../utilities'
import { ResponsiveImage } from '../responsive-image'
import * as styles from './index.module.css'
import LeftArrow from '../../images/left-arrow.svg'
import RightArrow from '../../images/right-arrow.svg'
import useKeyPress from 'react-use-keypress'

const ProjectCarousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [canGoNext, setCanGoNext] = useState(true)
  const [canGoPrevious, setCanGoPrevious] = useState(false)
  const maxIndex = slides.length - 1

  const setCanGo = (targetIndex) => {
    setCanGoNext(targetIndex < maxIndex)
    setCanGoPrevious(targetIndex > 0)
  }
  const next = () => {
    if (!canGoNext) {
      return
    }

    const targetIndex = currentIndex + 1
    setCurrentIndex(targetIndex)
    setCanGo(targetIndex)
  }
  const previous = () => {
    if (!canGoPrevious) {
      return
    }

    const targetIndex = currentIndex - 1
    setCurrentIndex(targetIndex)
    setCanGo(targetIndex)
  }

  useKeyPress('ArrowLeft', () => previous())
  useKeyPress('ArrowRight', () => next())

  return (
    <div className={styles.carousel}>
      <div className="visually-hidden" aria-live="polite">
        You are now viewing slide #{currentIndex + 1}
      </div>
      <div className={[styles.control, styles.previousControl].join(' ')}>
        <button
          onClick={previous}
          disabled={!canGoPrevious}
          aria-label={`Go to slide #${currentIndex}`}
          className={`button--reset ${styles.controlButton}`}
          style={{
            opacity: canGoPrevious ? 1 : 0.3,
          }}
        >
          <img src={LeftArrow} alt="" />
        </button>
      </div>
      <div className={[styles.control, styles.nextControl].join(' ')}>
        <button
          onClick={next}
          disabled={!canGoNext}
          aria-label={`Go to slide #${currentIndex + 2}`}
          className={`button--reset ${styles.controlButton}`}
          style={{
            opacity: canGoNext ? 1 : 0.3,
          }}
        >
          <img src={RightArrow} alt="" />
        </button>
      </div>
      <div className={styles.stage}>
        {slides.map((slide, index) => {
          const isActive = currentIndex === index
          return (
            <div
              key={slide.id}
              className={styles.slide}
              style={{
                opacity: isActive ? 1 : 0,
                transition: isActive
                  ? 'opacity 300ms ease-out'
                  : 'opacity 300ms ease-in',
                pointerEvents: isActive ? 'all' : 'none',
              }}
              aria-hidden={!isActive}
            >
              {util.projectMediaType(slide) === 'vimeo' ? (
                <VimeoPlayer
                  url={slide.vimeoUrl}
                  className={styles.media}
                ></VimeoPlayer>
              ) : (
                <ResponsiveImage image={slide.image} className={styles.media} />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ProjectCarousel
