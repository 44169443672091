import React from 'react'
import VimeoPlayer from '../vimeo-player'
import * as util from '../../utilities'
import { ResponsiveImage } from '../responsive-image'
import * as styles from './index.module.css'

const ProjectImage = ({ media }) => {
  return (
    <div className={styles.positioner}>
      <div className={styles.media}>
        {util.projectMediaType(media) === 'vimeo' ? (
          <VimeoPlayer url={media.vimeoUrl}></VimeoPlayer>
        ) : (
          <ResponsiveImage image={media.image} />
        )}
      </div>
    </div>
  )
}

export default ProjectImage
